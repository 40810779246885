import React from 'react';
import { Container } from '@material-ui/core';

type HomeProps = {}

const Home = (props: HomeProps) => {
    return (
        <Container>
            Home
        </Container>
    );
};

export default Home;
