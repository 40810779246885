import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import clsx from 'clsx';
import { AppBar, Button, Toolbar, Typography, IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../../store/reducers/index';
import { openDrawer } from '../../store/actions/layoutActions';
import { Link } from 'react-router-dom';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
    toolbar: {
        paddingRight: 24
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1
    },
}));

type NavigationProps = {
    title: string,
    open: () => void,
    isDrawerOpen: boolean,
}

const Navigation = (props: NavigationProps) => {
    const classes = useStyles();
    const { isDrawerOpen, open, title } = props;

    const logout = async () => {
        try {
            await firebase.default.auth().signOut();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <AppBar position="absolute" 
                className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}>

            <Toolbar className={classes.toolbar}>
                <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={open} 
                        className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}>

                    <Menu />
                </IconButton>
                <Typography component={Link} variant="h6" color="inherit" noWrap 
                        className={classes.title} to="/" style={{ textDecoration: 'none' }}>
                            
                    {title}
                </Typography>
                <Button color="inherit" onClick={logout}>Logout</Button>
            </Toolbar>
        </AppBar>
    );
}

const mapStateToProps = (state: RootState) => ({
    title: state.layout.title,
    isDrawerOpen: state.layout.isDrawerOpen,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    open: () => dispatch(openDrawer())
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
