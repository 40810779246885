import { actionNames } from '../actions/layoutActions';

type LayoutState = {
    title: string,
    isDense: boolean,
    isDrawerOpen: boolean
};

type LayoutReducer = LayoutState & {
    type: string
};

const initState: LayoutState = { title: 'Home', isDense: false, isDrawerOpen: false };

const reducer = (state = initState, action: LayoutReducer): LayoutState => {
    switch (action.type) {
        case actionNames.updateTitleAction:
            return {
                ...state,
                title: action.title
            };
        case actionNames.openDrawerAction:
            return {
                ...state,
                isDrawerOpen: true
            };
        case actionNames.closeDrawerAction:
            return {
                ...state,
                isDrawerOpen: false
            };
        default:
            return state;
    }
};

export default reducer;
