import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles } from '@material-ui/core/styles';
import { 
    Container, Paper, Backdrop, CircularProgress, Toolbar, FormControl, Select, MenuItem 
} from '@material-ui/core';
import Datatable, { ColumnConfig } from '../layout/Datatable';
import { RouteComponentProps } from 'react-router-dom';
import { Business } from './BusinessModel';

const useStyles = makeStyles(theme => createStyles({
    paper: { width: '100%', marginBottom: theme.spacing(2) },
    container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) },
    root: { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(1) },
    highlight: theme.palette.type === 'light' ? {
        color: theme.palette.secondary.main, 
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
    } : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
    },
    title: { flex: '1 1 100%'},
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '50%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const Businesses = (props: RouteComponentProps) => {
    const classes = useStyles();
    const [businesses, setBusinesses] = useState<Array<Business>>([]);
    const [hasMore, setHasMore] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [listViews, setListViews] = useState<Array<Record<string, string>>>([]);
    const [currentView, setCurrentView] = useState<Record<string, any>>();
    const [columns, setColumns] = useState<Array<ColumnConfig>>([]);
    const [query, setQuery] = useState<firebase.default.firestore.Query>();
    const limit = 10;

    useEffect(() => {
        const fetchConfig = async () => {
            const setupRef = firebase.default.firestore().collection('setup')
                .doc('business').collection('list-views');
            const results = await setupRef.get();
            setListViews(results.docs.map(doc => doc.data()));
        };

        fetchConfig();
    }, []);

    useEffect(() => {
        if (listViews.length > 0) setCurrentView(listViews[0]);
    }, [listViews]);

    useEffect(() => {
        if (currentView) {
            setColumns(currentView.columns.map((col: { name: string, label: string }) => {
                const data: Record<string, any> = {
                    name: col.name,
                    label: col.label
                }

                if (col.name === 'name') {
                    data.type = 'reference';
                    data.typeDetails = { referenceField: 'id' }
                }

                return data;
            }));

            let tempQuery = firebase.default.firestore().collection('businesses').orderBy('name');

            if (currentView?.filters) {
                currentView.filters.forEach((filter: Record<string, any>) => {
                    tempQuery = tempQuery.where(filter.field, filter.operator, filter.value);
                });
            }

            tempQuery = tempQuery.limit(limit);
            setQuery(tempQuery);
        }
    }, [currentView]);

    const fetchBusinesses = useCallback(async (
            request: firebase.default.firestore.Query, clearAll: boolean = false) => {

        setIsFetching(true);
        const results = await request.get();
        const records = results.docs.map(doc => ({ id: doc.id, ...doc.data() } as Business));
        setBusinesses(arr => clearAll ? records : arr.concat(records));
        setHasMore(results.size === limit);
        setIsFetching(false);
    }, []);

    useEffect(() => {
        if (query) {
            fetchBusinesses(query, true);
        }
    }, [query, fetchBusinesses]);

    const fetchMore = async () => {
        if (query) {
            await fetchBusinesses(query.startAfter(businesses[businesses.length - 1].name));
        }
    }

    const handleFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const selectedView = listViews.find(view => view.name === event.target.value);
        setCurrentView(selectedView);
    }

    return (
        <Container maxWidth="xl" className={classes.container}>
            <Paper className={classes.paper}>
                <Toolbar className={clsx(classes.root, {[classes.highlight]: false})}>
                    <FormControl className={classes.formControl}>
                        <Select value={currentView?.name ?? ''} onChange={handleFilterChange}>
                            {listViews.map(view => (
                                <MenuItem value={view.name} key={view.name}>{view.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Toolbar>
                <Datatable cols={columns} rows={businesses} hasMore={hasMore} pageSize={limit} 
                    fetchMore={fetchMore} {...props} />
            </Paper>
            <Backdrop className={classes.backdrop} open={isFetching}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
};

export default Businesses;
