import React, { useState } from 'react';
import { 
    Button, CssBaseline, Link, Paper, Box, Grid, Typography, Avatar, CircularProgress 
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
  
const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh'
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(1, 0, 1)
    },
}));

const Login = () => {
    const classes = useStyles();
    const [authenticating, setAuthenticating] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const authenticate = async () => {
        setAuthenticating(true);
        const provider = new firebase.default.auth.GoogleAuthProvider();
        try {
            const result = await firebase.default.auth().signInWithPopup(provider);
            setIsAuthenticated(result.user !== null);
        } catch (error) {
            setIsAuthenticated(false);
            console.error(error);
        }
        setAuthenticating(false);
    }

    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        {authenticating ? <CircularProgress /> : <LockOutlined />}
                    </Avatar>
                    <Typography component="h1" variant="h5">Sign in</Typography>
                    <form className={classes.form} noValidate>
                        <Button fullWidth variant="contained" className={classes.submit} 
                                color="primary" onClick={authenticate} disabled={authenticating}>

                            Sign In with Google
                        </Button>
                        <Box mt={5}>
                            <Typography variant="body2" color="textSecondary" align="center">
                                {'Copyright © '}
                                <Link color="inherit" href="https://www.blaqhub.com">BlaqHub</Link>
                                {` ${new Date().getFullYear()}.`}
                            </Typography>
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
};

export default Login;