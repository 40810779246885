import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Grid, CircularProgress } from '@material-ui/core';
import Layout from './app/layout/Layout';
import ProtectedRoute from './app/auth/ProtectedRoute';
import Home from './app/home/Home';
import Login from './app/auth/Login';
import Businesses from './app/business/Businesses';
import BusinessView from './app/business/BusinessView';

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null as firebase.default.User | null);

    useEffect(() => {
        const listener = firebase.default.auth().onAuthStateChanged(async user => {
            setIsLoading(true);
            setCurrentUser(user);
            const token = await user?.getIdTokenResult();

            if (user && token?.claims['admin'] !== true) {
                await firebase.default.auth().signOut();
            }

            setIsLoading(false);
        });

        return () => {
            listener();
        }
    }, [])

    if (isLoading) {
        return (
            <Grid container spacing={0} direction="column" alignItems="center" justify="center"
                    style={{ minHeight: '100vh' }}>

                <Grid item xs={3}>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    return (
        <Layout>
            <Switch>
                <Route path="/login" component={Login} />
                <ProtectedRoute path="/collection/business/:id" component={BusinessView} user={currentUser} />
                <ProtectedRoute path="/collection/business" component={Businesses} user={currentUser} />
                <ProtectedRoute path="/" component={Home} user={currentUser} />
            </Switch>
        </Layout>
    );
}

export default App;
