import React, { Dispatch } from 'react';
import { Action } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Drawer, List, Divider, IconButton, ListItem, ListItemIcon, ListItemText 
} from '@material-ui/core';
import { ChevronLeft, Business } from '@material-ui/icons';
import { RootState } from '../../store/reducers';
import { closeDrawer } from '../../store/actions/layoutActions';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9)
        }
    }
}));

type LayoutProps = {
    isDrawerOpen: boolean,
    close: () => void
};

const SideDrawer = (props: LayoutProps) => {
    const classes = useStyles();
    const { isDrawerOpen, close } = props;
    const paperClass = clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose);

    const mainListItems = [{
        path: '/collection/business',
        label: 'Businesses',
        icon: <Business />
    }].map(item => (
        <ListItem button component={Link} to={item.path} key={item.path}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
        </ListItem>
    ));

    return (
        <Drawer variant="permanent" open={isDrawerOpen} classes={{ paper: paperClass }}>
            <div className={classes.toolbarIcon}>
                <IconButton onClick={close}>
                    <ChevronLeft />
                </IconButton>
            </div>
            <Divider />
            <List>
                <div>{mainListItems}</div>
            </List>
        </Drawer>
    );
}

const mapStateToProps = (state: RootState) => ({
    isDrawerOpen: state.layout.isDrawerOpen
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    close: () => dispatch(closeDrawer())
});

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);
