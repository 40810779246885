import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Login from './app/auth/Login';
import { initStore } from './store';

const store = initStore();

ReactDOM.render(
    // <React.StrictMode>
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/" component={App} />
                </Switch>
            </Router>
        </Provider>,
    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
