import React, { useEffect, useState } from 'react';
import { 
    TableContainer, Table, TableBody, TableRow, TableCell, TablePagination, TableHead
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link, RouteComponentProps } from 'react-router-dom';

const useStyles = makeStyles(() => createStyles({ table: { minWidth: 750 } }));

export type ColumnConfig = {
    label: string,
    name: string,
    type?: string,
    typeDetails?: Record<string, string>
}

export type RowConfig = {
    id: string,
    [key: string]: any
}

export type DatatableProps = RouteComponentProps & {
    rows: Array<RowConfig>,
    cols: Array<ColumnConfig>,
    hasMore: boolean,
    pageSize?: number,
    fetchMore?: () => Promise<void>
}

const Datatable = (props: DatatableProps) => {
    const classes = useStyles();
    const { rows, cols, hasMore, pageSize, fetchMore, match } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageSize ?? 10);

    useEffect(() => {
        if (rows.length < (page + 1) * rowsPerPage) {
            setPage(Math.max(rows.length / rowsPerPage - 1, 0));
        }
    }, [page, rows, rowsPerPage]);

    const handleChangePage = async (event: unknown, newPage: number) => {
        if (fetchMore && newPage * rowsPerPage === rows.length) {
            await fetchMore();
        }

        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    return (
        <React.Fragment>
            <TableContainer>
                <Table className={classes.table} aria-labelledby="tableTitle"
                        size="medium" aria-label="enhanced table">

                    <TableHead>
                        <TableRow>
                            {cols.map(col => (
                                <TableCell key={col.name}><b>{col.label}</b></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(
                            page * rowsPerPage, page * rowsPerPage + rowsPerPage
                        ).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" 
                                    tabIndex={-1} key={row.id}>

                                    {cols.map((col, index) => {
                                        let value = row[col.name];

                                        if (col.type === 'reference' && col.typeDetails) {
                                            const url = match.url + '/' + 
                                                row[col.typeDetails.referenceField];
                                            value = <Link to={url}>{value}</Link>
                                        }

                                        return (
                                            <TableCell key={`${row.id}${index}`}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination component="div" page={page} count={hasMore ? -1 : rows.length} 
                rowsPerPage={rowsPerPage} rowsPerPageOptions={pageSize ? [pageSize] : [5, 10, 25]} 
                onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} />
        </React.Fragment>
    );
}

export default Datatable;
