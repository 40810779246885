export const actionNames = {
    updateTitleAction: 'UPDATE_TITLE',
    openDrawerAction: 'OPEN_DRAWER',
    closeDrawerAction: 'CLOSE_DRAWER'
};

export const updateTitle = (title: string) => ({
    type: actionNames.updateTitleAction,
    title
});

export const openDrawer = () => ({
    type: actionNames.openDrawerAction
});

export const closeDrawer = () => ({
    type: actionNames.closeDrawerAction
});
