import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

type ProtectedRouteProps = RouteProps & {
    component: any,
    user: firebase.default.User | null | undefined,
    [key: string]: any
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
    const { user } = props;
    return !user || !user.uid ? <Redirect to="/login" /> : <Route { ...props } />;
};

export default ProtectedRoute;