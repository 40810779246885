import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Paper, Toolbar, Typography } from '@material-ui/core';
import { Business } from './BusinessModel';
import { RouteComponentProps } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    paper: { width: '100%', marginBottom: theme.spacing(2) },
    container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) },
    title: { flex: '1 1 100%'},
  }),
);

type MatchParams = {
    id: string
};

type BusinessViewProps = RouteComponentProps<MatchParams> & {

};

const BusinessView = (props: BusinessViewProps) => {
    const classes = useStyles();
    const { match } = props;
    const [record, setRecord] = useState<Business>();

    useEffect(() => {
        const fetchRecord = async () => {
            const id = match.params.id;
            const ref = firebase.default.firestore().collection('businesses').doc(id);
            const result = await ref.get();
            setRecord(result.data() as Business);
        };

        fetchRecord();
    }, [match.params.id]);

    return (
        <Container maxWidth="xl" className={classes.container}>
            <Paper className={classes.paper}>
                <Toolbar className={classes.root}>
                    <Typography className={classes.title} color="inherit" variant="h5" 
                            component="div">

                        {record?.name}    
                    </Typography>
                </Toolbar>
                <form className={classes.root} noValidate autoComplete="off">
                    <div>
                        <TextField disabled label="Name" variant="outlined" 
                            value={record?.name ?? 'Enter Name'} />
                        <TextField disabled label="Address" variant="outlined" 
                            value={record?.address ?? 'Enter Address'} />
                        <TextField disabled label="Status" variant="outlined" 
                            value={record?.approvalStatus ?? 'Set Status'} />
                    </div>
                </form>
            </Paper>
        </Container>
    );
};

export default BusinessView;
